<template>
  <!--
    The view for the DestinationOS/Emma Dashboard.
    Displays grafana iframe
  -->
  <Portlet
    title="Dashboard"
    class="destination_dashboard"
    icon="tachometer-alt"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-2', { 'fa-spin' : initLoading }]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="getHosts()"
      />
      <select
        v-if="hosts"
        v-model="selectedHost"
        class="mr-2"
      >
        <option
          v-for="(host, index) in hosts"
          :key="`hosts-${ index }`"
          :value="host"
        >
          {{ host.name }}
        </option>
      </select>
      <select
        v-if="hostGraphs"
        v-model="selectedGraph"
        class="mr-2"
      >
        <option
          v-for="(hostGraph, index) in hostGraphs"
          :key="`hostGraphs-${ index }`"
          :value="hostGraph"
        >
          {{ hostGraph }}
        </option>
      </select>
      <select
        v-model="day"
        class="mr-3"
      >
        <option
          value="1h"
          selected
        >
          1 Hour
        </option>
        <option
          value="6h"
          selected
        >
          6 Hours
        </option>
        <option
          value="1d"
          selected
        >
          1 Day
        </option>
        <option
          value="2d"
        >
          2 Days
        </option>
        <option
          value="3d"
        >
          3 Days
        </option>
        <option
          value="4d"
        >
          4 Days
        </option>
        <option
          value="5d"
        >
          5 Days
        </option>
        <option
          value="6d"
        >
          6 Days
        </option>
        <option
          value="7d"
        >
          7 Days
        </option>
      </select>
    </template>
    <LoadingPlaceholder
      v-if="initLoading"
    />
    <template v-else>
      <template v-if="windowWidth >= 1300 && filteredGraphs.length > 1">
        <div class="row">
          <div class="col-6">
            <template v-for="(item, index) in firstHalf">
              <LoadingPlaceholder
                v-if="item.loading"
                :key="`graphl-${ index }`"
              />
              <img
                v-else-if="!item.err"
                :key="`graph-${ index }`"
                :src="`data:image/png;base64,${ item.url }`"
                class="mb-3 dash-img"
                style="width: 100%;"
                @click="singleOutGraph(item)"
              >
              <div
                v-else
                :key="`graphtss-${ index }`"
              >
                <p> Error while loading graph {{ item.graphId }} </p>
              </div>
            </template>
          </div>
          <div class="col-6">
            <template v-for="(item, index) in secondHalf">
              <LoadingPlaceholder
                v-if="item.loading"
                :key="`graphls-${ index }`"
              />
              <img
                v-else-if="!item.err"
                :key="`graphs-${ index }`"
                :src="`data:image/png;base64,${ item.url }`"
                class="mb-3 dash-img"
                style="width: 100%;"
                @click="singleOutGraph(item)"
              >
              <div
                v-else
                :key="`graphts-${ index }`"
              >
                <p> Error while loading graph {{ item.graphId }} </p>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-for="(item, index) in filteredGraphs">
          <LoadingPlaceholder
            v-if="item.loading"
            :key="`graphlf-${ index }`"
          />
          <img
            v-else-if="!item.err"
            :key="`graphf-${ index }`"
            :src="`data:image/png;base64,${ item.url }`"
            class="mb-3 dash-img"
            style="width: 100%;"
            @click="singleOutGraph(item)"
          >
          <div
            v-else
            :key="`graphe-${ index }`"
          >
            <p> Error while loading graph {{ item.graphId }} </p>
          </div>
        </template>
      </template>
    </template>
  </Portlet>
</template>

<script>
export default {
  name: "InstallationDetailDestinationDashboard",
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      initLoading: true,
      graphs: null,
      graphIds: [],
      filteredGraphs: [],
      cancelSource: null,
      cancelSources: [],
      firstHalf: null,
      secondHalf: null,
      hosts: null,
      hostGraphs: null,
      selectedGraph: "All",
      day: '1d',
      windowWidth: window.innerWidth,
      selectedHost: {name: "All", hostId: "All", host: "All", graphs: []}
    }
  },
  watch: {
    day () {
      this.selectedHost = {name: "All", hostId: "All", host: "All", graphs: []};
      this.cancelRequest();
      this.getHosts();
    },
    selectedHost () { 
      this.selectedGraph = "All";
      this.hostGraphs = [this.selectedGraph];
      let graphs = this.selectedHost.hostId != "All" ? this.graphIds.filter(x => x.hostId == this.selectedHost.hostId) : this.graphIds;

      graphs.forEach(e => { 
        if(e.name && !this.hostGraphs.includes(e.name)) {
          this.hostGraphs.push(e.name);
        }  
      });
      this.setHalves(graphs);
    },
    selectedGraph () {
      let graphs = this.selectedHost.hostId != "All" && this.selectedGraph != 'All'
      ? this.graphIds.filter(x => x.hostId == this.selectedHost.hostId && x.name == this.selectedGraph)
      : this.selectedHost.hostId != "All" && this.selectedGraph == 'All'
      ? this.graphIds.filter(x => x.hostId == this.selectedHost.hostId)
      : this.selectedHost.hostId == "All" && this.selectedGraph != 'All'
      ? this.graphIds.filter(x => x.name == this.selectedGraph)
      : this.selectedHost.hostId == "All" && this.selectedGraph == 'All'
      ? this.graphIds
      : this.graphIds;
      this.setHalves(graphs);
    }
  },
  created () {
    this.getHosts();
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy () { 
    this.cancelRequest();
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    singleOutGraph (graph) {
      let routeData = this.$router.resolve({path: `/installation/${ this.installationId }/destination-os-emma-dashboard/${ graph.graphId}/${ graph.hostId}`});
      window.open(routeData.href, '_blank');
    },
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    cancelRequest () {
      this.cancelSources.forEach((x) => {
        x.cancel();
      })
    },
    getHosts () {
      this.initLoading = true;
      let source = this.axios.CancelToken.source();
      this.cancelSources.push(source)
      let requestConfig = { cancelToken: source.token };
      this.axios.get(`/MonitoringSystem/GetZabbixHostsAndGraphsAsync?installationId=${this.installationId}`, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.hosts = response.data;
          this.hosts.unshift(this.selectedHost);
          this.hostGraphs = [this.selectedGraph];
          this.graphIds = [];

          this.hosts.forEach(h => {
            h.graphs.forEach(e => { 
            this.graphIds.push({hostId: h.hostId, graphId: e.graphId, name: e.name, loading: true, err: false, order: 5});
              if(e.name && !this.hostGraphs.includes(e.name)) {
                this.hostGraphs.push(e.name);
              }
            });
          });

          this.initLoading = false;  
        })
        .finally(async () => {
          this.setHalves(this.graphIds);
          let grouped = [];
          
          let countGrouped = 0;
          let count = 0;
          while(countGrouped != this.graphIds.length) {
            let sliced = this.graphIds.slice(count, count + 2);
            grouped.push(sliced);
            count += 2;
            countGrouped = countGrouped + sliced.length;
          }
          
          for (let arr of grouped) {
            if(!this._isDestroyed) {
              if(arr.length == 2) {
                await this.getGraphsDouble(arr[0], arr[1])
              }
              else {
                await this.getGraphs(arr[0])
              }
            }
          }
        })
    },
    setHalves (graphIds) {
      graphIds.sort((a,b) => {
        return a.sort - b.sort;
      });
        if(this.selectedGraph == "All") {
          this.filteredGraphs = graphIds;
        }
        else {
          this.filteredGraphs = graphIds.filter(x => x.name == this.selectedGraph);
        }

        this.firstHalf = [];
        this.secondHalf = [];

        for(let i = 0; i < this.filteredGraphs.length; i++) {
          if(i % 2) {
            this.firstHalf.push(this.filteredGraphs[i]);
          }
          else {
            this.secondHalf.push(this.filteredGraphs[i]);
          }
        }
    },
    async getGraphsDouble (graphObjOne, graphObjTwo) {
      graphObjOne.loading = true;
      graphObjTwo.loading = true;

      let sourceOne = this.axios.CancelToken.source();
      this.cancelSources.push(sourceOne)
      let requestConfigOne = { cancelToken: sourceOne.token };
      let sourceTwo = this.axios.CancelToken.source();
      this.cancelSources.push(sourceTwo)
      let requestConfigTwo = { cancelToken: sourceTwo.token };

      await this.axios.all([
        this.axios.post(`/MonitoringSystem/GetZabbixGraph?period=${ this.day }`, graphObjOne, requestConfigOne),
        this.axios.post(`/MonitoringSystem/GetZabbixGraph?period=${ this.day }`, graphObjTwo, requestConfigTwo)
      ]).then(
        this.axios.spread((responseOne, responseTwo) => {
          if (responseOne == null) {
            graphObjOne.err = `Error while getting Graph for ${graphObjOne.graphName}`;
          }
          if (responseOne.data == null) {
            graphObjOne.err = `Error while getting Graph for ${graphObjOne.graphName}`;
          }
          else {
            graphObjOne.err = null;
          }
          
          graphObjOne.url = responseOne.data.url;
          graphObjOne.order = responseOne.data.order;

          if (responseTwo == null) {
            graphObjTwo.err = `Error while getting Graph for ${graphObjTwo.graphName}`;
          }
          if (responseTwo.data == null) {
            graphObjTwo.err = `Error while getting Graph for ${graphObjTwo.graphName}`;
          }
          else {
            graphObjTwo.err = null;
          }
          
          graphObjTwo.url = responseTwo.data.url;
          graphObjTwo.order = responseTwo.data.order;
          
          this.setHalves(this.graphIds);
      }))
      .finally(() => {
        graphObjTwo.loading = false;
        graphObjOne.loading = false;
      })
      .catch((err) => {
        graphObjTwo.loading = false;
        graphObjOne.loading = false;
        graphObjTwo.err = `Error while getting Graph for ${graphObjTwo.graphName}: ${err.message}`;
        graphObjOne.err = `Error while getting Graph for ${graphObjOne.graphName}: ${err.message}`;
      }); 
    },
    async getGraphs (graphObj) {
      graphObj.loading = true;
      let source = this.axios.CancelToken.source();
      this.cancelSources.push(source)
      let requestConfig = { cancelToken: source.token };
      await this.axios.post(`/MonitoringSystem/GetZabbixGraph?period=${ this.day }`, graphObj, requestConfig)
        .then((response) => {
          if (response == null) {
            graphObj.err = `Error while getting Graph for ${graphObj.graphName}`;
          }
          if (response.data == null) {
            graphObj.err = `Error while getting Graph for ${graphObj.graphName}`;
          }
          else {
            graphObj.err = null;
          }
          
          graphObj.url = response.data.url;
          graphObj.order = response.data.order;
          
          this.setHalves(this.graphIds);
        })
        .finally(() => {
          graphObj.loading = false;
        })
        .catch((err) => {
          graphObj.loading = false;
          graphObj.err = `Error while getting Graph for ${graphObj.graphName}: ${err.message}`;
        }); 
    }
  }
}
</script>

<style scoped>

.dash-img {
  box-shadow: 0 1px 10px 1px rgb(0 0 0 / 10%);
    border: 1px solid #eee;
    cursor: pointer;
}
</style>